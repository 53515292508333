var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"top-img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.solutionData.banner),expression:"solutionData.banner"}],key:_vm.solutionData.banner,attrs:{"alt":"top"}})]),_c('div',{staticClass:"top-center"},[_c('p',[_vm._v(_vm._s(_vm.solutionData.name))]),_c('el-button',{staticClass:"banner-btn",attrs:{"plain":""},on:{"click":() => {
						this.$router.push('/formSub')
					}}},[_vm._v("立即咨询")])],1)]),_c('div',{staticClass:"container"},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.containerBgUrl),expression:"containerBgUrl",arg:"background-image"}],staticClass:"containerBg"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"plan-introduce"},[_vm._m(0),(!_vm.isMobileEnd)?_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.introduceBgUrl),expression:"introduceBgUrl",arg:"background-image"}],staticClass:"plan-introduce-center"},[_c('div',{staticClass:"plan-introduce-center-content"},[_c('div',{staticClass:"introduce-img"},[(_vm.isMp4)?_c('video',{attrs:{"controls":"","playsinline":""}},[_c('source',{attrs:{"src":_vm.fileUrl,"type":"video/mp4"}})]):_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.solutionData.introductionImg),expression:"solutionData.introductionImg"}],key:_vm.solutionData.introductionImg,attrs:{"alt":"方案介绍图"}})]),_c('div',{staticClass:"introduce-text"},[_c('div',{staticClass:"introduce-text-name"},[_vm._v(" "+_vm._s(_vm.solutionData.name)+" ")]),_c('div',{staticClass:"introduce-text-line"}),_c('div',{staticClass:"introduce-text-desc"},[_vm._v(" "+_vm._s(_vm.solutionData.introduction)+" ")]),_c('el-button',{staticClass:"introduce-text-btn",on:{"click":() => {
											this.$router.push('/formSub')
										}}},[_vm._v("方案咨询")])],1)])]):_c('div',{staticClass:"plan-introduce-768"},[_c('div',{staticClass:"plan-introduce-768-content"},[_c('div',{staticClass:"introduce-768-img"},[_c('div',{staticClass:"introduce-768-img-D"}),_c('div',{staticClass:"introduce-768-img-T"},[(_vm.isMp4)?_c('video',{attrs:{"controls":"","playsinline":""}},[_c('source',{attrs:{"src":_vm.fileUrl,"type":"video/mp4"}})]):_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
											_vm.solutionData.introductionImg
										),expression:"\n\t\t\t\t\t\t\t\t\t\t\tsolutionData.introductionImg\n\t\t\t\t\t\t\t\t\t\t"}],key:_vm.solutionData.introductionImg,attrs:{"alt":"方案介绍图"}})])]),_c('div',{staticClass:"introduce-768-text"},[_c('div',{staticClass:"introduce-768-text-name"},[_vm._v(" "+_vm._s(_vm.solutionData.name)+" ")]),_c('div',{staticClass:"introduce-768-text-line"}),_c('div',{staticClass:"introduce-768-text-desc"},[_vm._v(" "+_vm._s(_vm.solutionData.introduction)+" ")]),_c('el-button',{staticClass:"introduce-768-text-btn",attrs:{"type":"primary"}},[_vm._v("方案咨询")])],1)])])]),_c('div',{staticClass:"plan-architecture"},[_vm._m(1),_c('div',{staticClass:"plan-architecture-center"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.solutionData.schemaImg),expression:"solutionData.schemaImg"}],key:_vm.solutionData.schemaImg,attrs:{"alt":"架构图"}})])]),_c('div',{staticClass:"plan-feature"},[_vm._m(2),_c('div',{staticClass:"plan-feature-center"},_vm._l((_vm.solutionData.features),function(i,index){return _c('div',{key:index,staticClass:"plan-feature-center-item"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(i.featureImg),expression:"i.featureImg"}],key:i.featureImg,attrs:{"alt":"方案特点"}}),_c('h5',[_vm._v(_vm._s(i.featureName))]),_c('p',[_vm._v(_vm._s(i.featureIntroduction))])])}),0)])])]),_c('div',{staticClass:"left"}),_c('div',{staticClass:"right"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',{staticClass:"title-text"},[_vm._v("方案介绍")]),_c('span',{staticClass:"title-desc"},[_vm._v("将智能产品因地制宜，达到个性化使用目的")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',{staticClass:"title-text"},[_vm._v("系统架构")]),_c('span',{staticClass:"title-desc"},[_vm._v("将系统架构可视化，带您了解体系节点")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',{staticClass:"title-text"},[_vm._v("方案特点")]),_c('span',{staticClass:"title-desc"},[_vm._v("精准提炼本方案，高效了解其优势")])])
}]

export { render, staticRenderFns }